<template>
  <div class="vx-row mt-3">
    <div class="w-1/2">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>CL Request ID</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="create.referenceCode" disabled />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="territory.selected"
            :options="territory.options"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            name="territory"
            v-validate="'required'"
            @input="selectedTerritory"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <span v-show="errors.has('territory')" class="text-danger">{{
            errors.first("territory")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Code</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="customer.selected"
            :options="customer.options"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :internal-search="false"
            :limit="4"
            placeholder=" Type to search"
            track-by="code"
            label="code"
            name="customer"
            @search-change="handlerSearchCustomer"
            @select="selectedCustomer"
            :disabled="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }}{{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >( {{ props.option.code }}){{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
          <span v-show="errors.has('customer')" class="text-danger">{{
            errors.first("customer")
          }}</span>
        </div>
      </div>
      <!-- <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Dist. Channel </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.customer_category"
            disabled
          />
        </div>
      </div> -->
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Group </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.customer_group"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Risk Category</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.risk_category"
            disabled
          />
        </div>
      </div>
      <!-- <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Current Payment Term</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.credit_limit"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Current TOP</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.payment_term"
            disabled
          />
        </div>
      </div> -->
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Highest Transaction</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.highest_transaction"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>PKP</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" v-model="customerData.is_pkp" disabled />
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%">KTP Image</vs-divider>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>KTP Image</span>
          </div>
          <div class="vx-col w-full">
            <input
              name="file_ktp"
              class="w-full inputx"
              type="file"
              ref="file_ktp"
              accept=".jpg, .jpeg, .png"
              @change="onImageChange($event, 'ktp')"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 400px; max-width: 750px; padding-left: 20%"
              :src="datasImg.urlKtp"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-button
              class="mt-2"
              color="success"
              type="border"
              icon-pack="feather"
              icon="icon-upload"
              @click="handleUploadImage(imageDescription.ktp)"
            >
              Upload
            </vs-button>
          </div>
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%"
          >Outlet Image</vs-divider
        >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Outlet Image</span>
          </div>
          <div class="vx-col w-full">
            <input
              name="file_outlet"
              class="w-full inputx"
              type="file"
              ref="file_outlet"
              accept=".jpg, .jpeg, .png"
              @change="onImageChange($event, 'outlet')"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 400px; max-width: 750px; padding-left: 20%"
              :src="datasImg.urlOutlet"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-button
              class="mt-2"
              color="success"
              type="border"
              icon-pack="feather"
              icon="icon-upload"
              @click="handleUploadImage(imageDescription.outlet)"
            >
              Upload
            </vs-button>
          </div>
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%">NPWP Image</vs-divider>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>NPWP Image</span>
          </div>
          <div class="vx-col w-full">
            <input
              name="file_npwp"
              class="w-full inputx"
              type="file"
              ref="file_npwp"
              accept=".jpg, .jpeg, .png"
              @change="onImageChange($event, 'npwp')"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 400px; max-width: 750px; padding-left: 20%"
              :src="datasImg.urlNpwp"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-button
              class="mt-2"
              color="success"
              type="border"
              icon-pack="feather"
              icon="icon-upload"
              @click="handleUploadImage(imageDescription.npwp)"
            >
              Upload
            </vs-button>
          </div>
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%"
          >SPPKP Image</vs-divider
        >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>SPPKP Image</span>
          </div>
          <div class="vx-col w-full">
            <input
              name="file_sppkp"
              class="w-full inputx"
              type="file"
              ref="file_sppkp"
              accept=".jpg, .jpeg, .png"
              @change="onImageChange($event, 'sppkp')"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 400px; max-width: 750px; padding-left: 20%"
              :src="datasImg.urlSppkp"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-button
              class="mt-2"
              color="success"
              type="border"
              icon-pack="feather"
              icon="icon-upload"
              @click="handleUploadImage(imageDescription.sppkp)"
            >
              Upload
            </vs-button>
          </div>
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%"
          >Invoice Image</vs-divider
        >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Invoice Image</span>
          </div>
          <div class="vx-col w-full">
            <input
              name="file_invoice"
              class="w-full inputx"
              type="file"
              ref="file_invoice"
              accept=".jpg, .jpeg, .png"
              @change="onImageChange($event, 'invoice')"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 400px; max-width: 750px"
              :src="datasImg.urlInvoice"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-button
              class="mt-2"
              color="success"
              type="border"
              icon-pack="feather"
              icon="icon-upload"
              @click="handleUploadImage(imageDescription.invoice)"
            >
              Upload
            </vs-button>
          </div>
        </div>
      </div>
      <div
        class="vx-col margin-btm"
        style="width: 100%; padding-bottom: 15px"
        v-if="customer.selected != null"
      >
        <vs-divider style="width: 100%; margin-left: 0%">CL Image</vs-divider>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>CL Image</span>
          </div>
          <div class="vx-col w-full">
            <input
              name="file_cl"
              class="w-full inputx"
              type="file"
              ref="file_cl"
              accept=".jpg, .jpeg, .png"
              @change="onImageChange($event, 'cl')"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img
              style="max-height: 400px; max-width: 750px; padding-left: 20%"
              :src="datasImg.urlCl"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-button
              class="mt-2"
              color="success"
              type="border"
              icon-pack="feather"
              icon="icon-upload"
              @click="handleUploadImage(imageDescription.clImage)"
            >
              Upload
            </vs-button>
          </div>
        </div>
      </div>
    </div>

    <div class="w-1/2">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Valid From</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <flat-pickr
            :config="configDatePicker"
            v-model="create.dateApply"
            placeholder="Valid From"
            style="width: 100%"
            name="ValidFrom"
            v-validate="'required'"
            disabled
          />
          <span v-show="errors.has('ValidFrom')" class="text-danger">
            Valid From is required
          </span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Notes</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-textarea
            class="w-full"
            v-model="create.notes"
            name="notes"
            v-validate="'required'"
          />
          <span v-show="errors.has('notes')" class="text-danger">{{
            errors.first("notes")
          }}</span>
        </div>
      </div>
      <div class="vx-row" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3">
          <label
            >Attachment
            <small style="color: red"
              >(only: jpg, jpeg, pdf, doc, docx, png, xlsx, xls)</small
            ></label
          >
        </div>
        <div class="vx-col sm:w-2/3">
          <div class="vx-col w-1/2">
            <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/4 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <vs-divider style="width: 60%; margin-left: 30%">
          List Attachment
        </vs-divider>
        <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 10%">
          <table class="vs-table vs-table--tbody-table">
            <template>
              <tr
                class="tr-values vs-table--tr tr-table-state-null selected"
                v-bind:key="tr.NameFile"
                v-for="(tr, i) in fileAttachment"
              >
                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                <td class="td vs-table--td">
                  <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      @click.stop="handleShowAttachment(tr)"
                    />
                  </vx-tooltip>
                </td>

                <td class="td vs-table--td">
                  <template>
                    <vx-tooltip text="Delete">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click.stop="handleDeleteAttachment(i)"
                      />
                    </vx-tooltip>
                  </template>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Request</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full flex justify-between">
          <vs-radio v-model="create.request" vs-value="1" vs-name="request">
            Credit Limit
          </vs-radio>
          <vs-radio v-model="create.request" vs-value="2" vs-name="request">
            Payment Term
          </vs-radio>
          <vs-radio v-model="create.request" vs-value="3" vs-name="request">
            All
          </vs-radio>
        </div>
      </div>
      <hr style="border-top: 1px solid black" />
      <div
        class="vx-row margin-btm mt-3"
        style="width: 100%; padding-bottom: 15px"
        v-if="create.request == '1' || create.request == '3'"
      >
        <div class="vx-col sm:w-2/5 w-full">
          <span>Credit Limit Request</span>
        </div>
        <div class="vx-row sm:w-3/5">
          <div class="vx-row sm:w-2/3 w-full">
            <NumberInput class="w-full" v-model="create.creditLimitRequest" />
            <!-- <vs-input class="w-full" v-model="create.creditLimitRequest" /> -->
            <span v-if="isCL" class="text-danger">
              * Credit Limit is bigger than current Credit Limit
            </span>
          </div>
          <div class="sm:w-1/3 w-full">
            <vs-input
              class="w-full ml-4"
              v-model="create.paymentTermRequest"
              disabled
            />
          </div>
        </div>
      </div>
      <div
        class="vx-row margin-btm mt-3"
        style="width: 100%; padding-bottom: 15px"
        v-if="create.request == '2' || create.request == '3'"
      >
        <div class="vx-col sm:w-1/3 w-full">
          <span>TOP Request</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="top.selected"
            :options="top.options"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            name="topData"
            label="name"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <span v-show="errors.has('topData')" class="text-danger"
            >TOP is required</span
          >
          <span v-if="isTop" class="text-danger">
            * TOP is bigger than current TOP
          </span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Reason</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="reason.selected"
            :options="reason.options"
            :show-labels="false"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="ID"
            label="reason"
            name="reason"
            v-validate="'required'"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.reason }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.reason }}</span>
              </div>
            </template>
          </multiselect>
          <span v-show="errors.has('reason')" class="text-danger">{{
            errors.first("reason")
          }}</span>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Current Credit Limit</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.credit_limit"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Current TOP</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="customerData.payment_term"
            disabled
          />
        </div>
      </div>

      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Evaluation Periode</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="EvaPeriod"
            type="number"
            name="evaluation_period"
            min="0"
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Exclude SO Type</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="soType.selected"
            :options="soType.options"
            :show-labels="false"
            multiple
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="ID"
            label="Code"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.Code }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.Code }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>

      <div class="vx-row margin-btm mb-3" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <vs-button
          @click="handleCalculate()"
          icon-pack="feather"
          icon="icon-plus-square"
        >
          Calculate
        </vs-button>
      </div>

      <hr style="border-top: 1px solid black" />

      <div
        class="vx-row margin-btm mt-3"
        style="width: 100%; padding-bottom: 15px"
      >
        <div class="vx-col sm:w-1/3 w-full">
          <span>Total Sales (Amount) </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.total_sales"
            disabled
          />
        </div>
      </div>

      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Average Sales (Amount)</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full" style="height: max-content">
          <vs-textarea
            class="w-full"
            v-model="calculateData.average_sales"
            disabled
          >
          </vs-textarea>
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Average Payment (Day)</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.average_payment"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Number of Late Payment (Invoice) </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.numb_of_late_payment"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Average Late Payment (Day) </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.average_late_payment"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Active Transaction (Invoice)</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.active_transaction"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>AR Overdue (Amount) </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input
            class="w-full"
            v-model="calculateData.ar_over_due"
            disabled
          />
        </div>
      </div>
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
        <div class="vx-col sm:w-1/3 w-full"></div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-button
            @click="handleSubmit"
            color="success"
            type="border"
            icon-pack="feather"
            icon="icon-save"
          >
            Save
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import NumberInput from "@/components/master/NumberInput.vue";
import { ref } from "vue";

export default {
  components: { flatPickr, NumberInput },
  props: {
    id: Number,
  },
  async mounted() {
    await this.getData();
  },
  data() {
    return {
      datasImg: {
        img_ktp: null,
        img_outlet: null,
        img_npwp: null,
        img_sppkp: null,
        img_invoice: null,
        img_additional: null,
        urlKtp: "",
        urlNpwp: "",
        urlSppkp: "",
        urlInvoice: "",
        urlOutlet: "",
        urlCl: "",
      },
      historyData: [],
      history: false,
      detail: false,
      totalSales: 0,
      isCalculate: false,
      EvaPeriod: 0,
      create: {
        referenceCode: null,
        dateApply: "",
        amount: 0,
        notes: null,
        paymentTermRequest: null,
        creditLimitRequest: null,
        request: "",
      },
      territory: {
        selected: null,
        options: [],
      },
      customer: {
        selected: null,
        options: [],
        search: "",
      },
      top: {
        selected: null,
        options: [],
      },
      reason: {
        selected: null,
        options: [],
      },
      soType: {
        selected: [],
        options: [],
      },
      customerData: {},
      calculateData: {},

      configDatePicker: {
        dateFormat: "d F Y",
      },
      imageDescription: {
        ktp: "ktp",
        outlet: "outlet",
        npwp: "npwp",
        sppkp: "sppkp",
        invoice: "invoice",
        clImage: "cl_image",
      },
      defaultNotes:
        "Average Sales (Amount) = Total Sales (Amount) / Evaluation Period",
      fileAttachment: [],
      newAttachment: [],
      isTop: false,
      isCL: false,
      changeCustomer: 0,
      changeData: 0,
      isNothingInvoice: false,
    };
  },
  methods: {
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      this.$emit("close", params);
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
    dateFormat2(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMMM YYYY");
      }
      return a;
    },
    handleClickDatePicker() {
      console.log("ini lognya", this.create.dateApply);
    },

    async getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/credit-limit-requests-id", {
          params: {
            id: this.id,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.create.referenceCode = resp.data.credit_limit_request.Code;
            this.create.dateApply = this.dateFormat2(
              resp.data.credit_limit_request.ValidFrom
            );
            this.create.notes = resp.data.credit_limit_request.Notes;
            // let rq = "";
            // if (resp.data.credit_limit_request.RequestType == 1) {
            //   rq = "credit_limit";
            // } else if (resp.data.credit_limit_request.RequestType == 2) {
            //   rq = "payment_term";
            // } else {
            //   rq = "all";
            // }
            this.create.request = resp.data.credit_limit_request.RequestType;
            console.log(">>>>req", this.create.request);
            this.create.creditLimitRequest =
              resp.data.credit_limit_request.CreditLimitRequest;
            this.customer.selected = {
              id: resp.data.credit_limit_request.CustomerID,
              name: resp.data.credit_limit_request.CustomerName,
              code: resp.data.credit_limit_request.CustomerCode,
            };
            this.territory.selected = {
              id: resp.data.credit_limit_request.TerritoryID,
              name: resp.data.credit_limit_request.TerritoryName,
            };
            this.top.selected = {
              id: resp.data.credit_limit_request.PaymentTermID,
              name: resp.data.credit_limit_request.PaymentTermCode,
            };
            this.reason.selected = {
              id: resp.data.credit_limit_request.ReasonID,
            };
            this.soType.selected = resp.data.credit_limit_request
              .CreditLimitRequestSalesOrderType
              ? resp.data.credit_limit_request.CreditLimitRequestSalesOrderType
                  .length > 0
                ? resp.data.credit_limit_request
                    .CreditLimitRequestSalesOrderType
                : [{ ID: 0, Code: "-" }]
              : [{ ID: 0, Code: "-" }];

            if (resp.data.attachment != null) {
              resp.data.attachment.forEach((el) => {
                this.fileAttachment.push({
                  id: el.ID,
                  NameFile: el.name,
                  PathFile: el.url,
                });
              });
            }

            this.create.paymentTermRequest =
              resp.data.credit_limit_request.PaymentTermRequest;
            this.customerData.credit_limit_request_old =
              resp.data.credit_limit_request.CurrentCreditLimit;
            this.customerData.credit_limit =
              resp.data.credit_limit_request.CurrentCreditLimit;
            this.customerData.payment_term =
              resp.data.credit_limit_request.CurrentPaymentTerm;
            this.customerData.evaluation_period =
              resp.data.credit_limit_request.EvaluationPeriod;
            this.EvaPeriod = resp.data.credit_limit_request.EvaluationPeriod;

            this.isCalculate = true;
            this.getAllTerritory();
            this.getAllCustomerByTerritory();
            this.getAllReason();
            this.getAllTOP();
            this.getAllSoType();
            this.getAllCustomerByID();
            this.handleCalculate();

            this.changeData = 1;

            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            if (resp.data.records) {
              for (let index = 0; index < resp.data.records.length; index++) {
                this.territory.options.push(resp.data.records[index]);
              }
              if (this.territory.options.length > 0) {
                //find index where id = this.territory.selected
                let index = this.territory.options.findIndex(
                  (x) => x.id == this.territory.selected.id
                );
                if (index != -1) {
                  this.territory.selected = this.territory.options[index];
                } else {
                  this.territory.selected = { code: "", id: 0 };
                }
              } else {
                this.territory.options = [];
                this.territory.selected = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.territory.options = [];
              this.territory.selected = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    selectedTerritory() {
      this.customer.selected = null;
      this.getAllCustomerByTerritory();
    },
    getAllCustomerByTerritory(query) {
      if (this.territory.selected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Required",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      this.$http
        .get("/api/v1/master/customer-by-territory", {
          params: {
            territory_id: this.territory.selected.id,
            search: query ? query : "",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customer.options = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
          console.log(">>>>", this.customer.options);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handlerSearchCustomer(searching) {
      if (searching.length > 3) {
        this.customer.options = [];
        this.search = searching;
        this.getAllCustomerByTerritory(searching);
      }
    },
    getAllTOP() {
      this.$http.get("/api/v1/master/payment-term").then((resp) => {
        if (resp.status == "success") {
          if (resp.data.records) {
            for (let index = 0; index < resp.data.records.length; index++) {
              this.top.options.push(resp.data.records[index]);
            }
            if (this.top.options.length > 0) {
              let index = this.top.options.findIndex(
                (x) => x.id == this.top.selected.id
              );
              if (index != -1) {
                this.top.selected = this.top.options[index];
              } else {
                this.top.selected = { name: "", id: 0 };
              }
            } else {
              this.top.options = [];
              this.top.selected = { name: "", id: 0 };
            }
          } else {
            this.top.options = [];
            this.top.selected = { name: "", id: 0 };
          }
        } else {
          this.$vs.loading.close();
        }
      });
    },
    selectedCustomer() {
      this.changeCustomer += 1;
      if (this.$refs.file_ktp) {
        this.$refs.file_ktp.value = null;
      }
      if (this.$refs.file_outlet) {
        this.$refs.file_outlet.value = null;
      }
      if (this.$refs.file_npwp) {
        this.$refs.file_npwp.value = null;
      }
      if (this.$refs.file_sppkp) {
        this.$refs.file_sppkp.value = null;
      }
      if (this.$refs.file_invoice) {
        this.$refs.file_invoice.value = null;
      }
      if (this.$refs.file_cl) {
        this.$refs.file_cl.value = null;
      }

      this.fileAttachment = [];
      this.datasImg = {
        img_ktp: null,
        img_outlet: null,
        img_npwp: null,
        img_sppkp: null,
        img_invoice: null,
        img_additional: null,
        urlKtp: "",
        urlNpwp: "",
        urlSppkp: "",
        urlInvoice: "",
        urlOutlet: "",
        urlCl: "",
      };
      this.customerData = {};
      this.calculateData = {};
      this.create = {
        referenceCode: null,
        dateApply: ref(new Date()),
        amount: 0,
        notes: null,
        paymentTermRequest: null,
        creditLimitRequest: 0,
        request: null,
      };
      this.isNothingInvoice = false;
      this.getAllCustomerByID();
    },
    getAllCustomerByID() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-by-id", {
          params: {
            id: this.customer.selected.id,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerData = resp.data;
            if (this.customerData.is_pkp == "1") {
              this.customerData.is_pkp = "YES";
            } else {
              this.customerData.is_pkp = "NO";
            }

            this.customerData.credit_limit_request_old =
              this.customerData.credit_limit;
            let cust_credit_limit = Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(this.customerData.credit_limit);
            this.customerData.credit_limit = cust_credit_limit;

            let cust_highest_transaction = Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(this.customerData.highest_transaction);
            this.customerData.highest_transaction =
              this.customerData.highest_transaction_date +
              " - " +
              cust_highest_transaction;
            let customer_group = "";
            //convert customer group 1,2,3 until pricing group to be like this GT-A4-B0C-C0K-E06
            if (this.customerData.customer_group1 != null) {
              customer_group += this.customerData.customer_group1;
            }
            if (this.customerData.customer_group2 != null) {
              customer_group += "-" + this.customerData.customer_group2;
            }
            if (this.customerData.customer_group3 != null) {
              customer_group += "-" + this.customerData.customer_group3;
            }
            if (this.customerData.pricing_group != null) {
              customer_group += "-" + this.customerData.pricing_group;
            }
            this.customerData.customer_group = customer_group;
            let risk_category = "";
            if (this.customerData.risk_category_code != null) {
              risk_category += this.customerData.risk_category_code;
              risk_category += " - " + this.customerData.risk_category_name;
            }
            this.customerData.risk_category = risk_category;

            for (let i = 0; i < this.customerData.customer_image.length; i++) {
              if (this.customerData.customer_image[i].description == "ktp") {
                this.datasImg.urlKtp =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_ktp = this.customerData.customer_image[i];
              } else if (
                this.customerData.customer_image[i].description == "outlet"
              ) {
                this.datasImg.urlOutlet =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_outlet = this.customerData.customer_image[i];
              } else if (
                this.customerData.customer_image[i].description == "npwp"
              ) {
                this.datasImg.urlNpwp =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_npwp = this.customerData.customer_image[i];
              } else if (
                this.customerData.customer_image[i].description == "sppkp"
              ) {
                this.datasImg.urlSppkp =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_sppkp = this.customerData.customer_image[i];
              } else if (
                this.customerData.customer_image[i].description == "invoice"
              ) {
                this.datasImg.urlInvoice =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_invoice = this.customerData.customer_image[i];
              } else if (
                this.customerData.customer_image[i].description == "cl_image"
              ) {
                this.datasImg.urlCl =
                  this.customerData.customer_image[i].file_path;
                this.datasImg.img_cl = this.customerData.customer_image[i];
              } else {
                continue;
              }
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllReason() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/reason", {
          params: {
            reason_group: "Credit Limit",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            for (let index = 0; index < resp.data.records.length; index++) {
              this.reason.options.push(resp.data.records[index]);
            }
            if (this.reason.options.length > 0) {
              let index = this.reason.options.findIndex(
                (x) => x.ID == this.reason.selected.id
              );
              if (index != -1) {
                this.reason.selected = this.reason.options[index];
              } else {
                this.reason.selected = { reason: "", id: 0 };
              }
            } else {
              this.reason.options = [];
              this.reason.selected = { reason: "", id: 0 };
            }
          } else {
            this.reason.options = [];
            this.reason.selected = { reason: "", id: 0 };
          }
          this.$vs.loading.close();
        });
    },
    getAllSoType() {
      this.$vs.loading();
      this.$http.get("/api/v1/master/sales-order-type").then((resp) => {
        if (resp.data.records) {
          this.soType.options = resp.data.records;
          this.soType.options = [{ Code: "-", ID: 0 }, ...this.soType.options];
        } else {
          this.soType.options = [];
          this.soType.selected = { Code: "", ID: 0 };
        }
      });
    },
    getDetailCustomer() {
      if (this.customer.selected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Required",
          text: "Customer is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      if (this.detail == true) {
        this.detail = false;
        this.history = false;
      } else {
        this.detail = true;
        this.history = false;
      }
    },
    getDetailHistory() {
      if (this.history == true) {
        this.history = false;
        this.detail = false;
      } else {
        this.history = true;
        this.detail = false;
      }
    },
    handleCalculate() {
      if (
        this.customerData.evaluation_period == null ||
        this.customerData.evaluation_period <= 0
      ) {
        this.$vs.notify({
          color: "danger",
          title: "Required",
          text: "Evaluation Periode is required or bigger than 0",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      if (this.customer.selected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Required",
          text: "Customer is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      if (
        this.customerData.credit_limit == 0 ||
        this.customerData.credit_limit == null
      ) {
        this.calculateData = {
          total_sales: 0,
          average_sales: "-",
          average_payment: 0,
          numb_of_late_payment: 0,
          average_late_payment: 0,
          active_transaction: 0,
          ar_over_due: 0,
          average_sales_response: 0,
        };
        this.$vs.notify({
          color: "danger",
          title: "Required",
          text: "Current Credit Limit is 0 that mean customer is not pass credit limit",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return;
      }
      // if (this.soType.selected.length == 0) {
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Required",
      //     text: "SoType is required",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return;
      // }
      this.totalSales = 0;
      this.totalAverageSales = 0;

      if (this.soType.selected.length == 0) {
        this.soType.selected = [{ ID: 0, Code: "-" }];
      }
      this.isNothingInvoice = false;

      this.$vs.loading();
      this.$http
        .get("/api/v1/master/calculate-credit-risk", {
          params: {
            sales_order_type:
              this.soType.selected.length > 0
                ? this.soType.selected.map((item) => item.ID)
                : null,
            customer_id: this.customer.selected.id,
            evaluation_period: this.EvaPeriod,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            this.calculateData = resp.data;
            this.totalSales = resp.data.total_sales;
            let avg_sales = "";

            this.calculateData.total_average_sales_old =
              resp.data.total_average_sales;
            avg_sales =
              "Total average = " +
              Intl.NumberFormat("en", {
                minimumFractionDigits: 2,
              }).format(resp.data.total_average_sales);
            for (let i = 0; i < resp.data.average_sales_response.length; i++) {
              avg_sales +=
                "\n" +
                new Date(
                  new Date().setMonth(
                    resp.data.average_sales_response[i].month - 1
                  )
                ).toLocaleString("default", { month: "long" }) +
                " =" +
                Intl.NumberFormat("en", {
                  minimumFractionDigits: 2,
                }).format(resp.data.average_sales_response[i].month_total);

              if (resp.data.average_sales_response[i].month_total == 0) {
                this.isNothingInvoice = true;
              }
            }
            this.calculateData.average_sales = avg_sales;

            this.calculateData.ar_over_due_old = resp.data.ar_over_due;
            this.calculateData.ar_over_due = Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(resp.data.ar_over_due);

            this.calculateData.total_sales_old = resp.data.total_sales;
            this.calculateData.total_sales = Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(resp.data.total_sales);

            this.isCalculate = true;
            this.$vs.loading.close();
          } else {
            this.calculateData = {
              total_sales: 0,
              average_sales: "-",
              average_payment: 0,
              numb_of_late_payment: 0,
              average_late_payment: 0,
              active_transaction: 0,
              ar_over_due: 0,
              average_sales_response: 0,
            };
            this.$vs.notify({
              color: "danger",
              title: "Failed",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }

          this.$vs.loading.close();
        });
    },
    handleSubmit() {
      //submit using form:

      // if (this.calculateData.is_pass_moq == false) {
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Failed",
      //     text: "Customer is not pass Minimum Order Quantity (MOQ)",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return;
      // }
      //check if nothing invoice then attachment is required

      if (this.isNothingInvoice == true) {
        if (this.fileAttachment.length == 0) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Attachment is required Because Nothing Transaction",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      }
      //check if ktpphoto is empty then return
      if (this.datasImg.urlKtp == "" || this.datasImg.urlOutlet == "") {
        this.$vs.notify({
          color: "danger",
          title: "Failed",
          text: "Image KTP and Outlet is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      if (this.customerData.is_pkp == "YES") {
        if (
          this.datasImg.urlInvoice == "" ||
          this.datasImg.urlSppkp == "" ||
          this.datasImg.urlNpwp == ""
        ) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Image NPWP,Invoice and SPPKP is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      }

      if (this.isCL == true) {
        if (this.fileAttachment.length == 0) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Attachment is required because Credit Limit is bigger than current Credit Limit",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      }

      if (this.isTop == true) {
        if (this.fileAttachment.length == 0) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Attachment is required because TOP is bigger than current TOP",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      }

      if (this.create.creditLimitRequest != 0 && this.isCL == true) {
        if (this.create.creditLimitRequest == this.customerData.credit_limit) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Credit Limit Request cannot be same with current credit limit",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      }
      if (this.top.selected != null) {
        if (
          this.top.selected.due_invoice_period !=
            this.customerData.payment_term_due_periode &&
          this.fileAttachment.length == 0
        ) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Attachment is required because TOP is not same with current TOP",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      }
      if (this.create.creditLimitRequest <= 0 && this.top.selected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Failed",
          text: "Credit Limit Request or Payment Term is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      if (this.isCalculate == false && this.isCL == true) {
        this.$vs.notify({
          color: "danger",
          title: "Failed",
          text: "Please calculate first",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      // if (this.create.creditLimitRequest <= 0 && this.top.selected == null) {
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Failed",
      //     text: "Credit Limit Request or Payment Term is required",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return;
      // }

      let requestType;
      if (this.create.request == "1") {
        this.top.selected = { id: 0, name: "" };
        requestType = 1;
      } else if (this.create.request == "2") {
        this.create.creditLimitRequest = 0;
        requestType = 2;
      } else if (this.create.request == "3") {
        requestType = 3;
        if (this.top.selected.id == null || this.top.selected.id == 0) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Payment Term is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        if (
          this.create.creditLimitRequest == null ||
          this.create.creditLimitRequest == 0
        ) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Credit Limit Request is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      }
      this.create.dateApply = moment(this.create.dateApply).format(
        "YYYY-MM-DD"
      );

      this.$vs.loading();

      this.$http
        .put("/api/v1/master/update-credit-limit-request", {
          id: this.id,
          code: this.create.referenceCode,
          customer_id: this.customer.selected.id,
          customer_code: this.customerData.customer_code,
          customer_name: this.customerData.customer_name,
          territory_id: this.territory.selected.id,
          territory_name: this.territory.selected.name,
          valid_from: this.create.dateApply,
          notes: this.create.notes,
          request_type: requestType,
          credit_limit_request: Number(this.create.creditLimitRequest),
          payment_term_id: this.top.selected ? this.top.selected.id : null,
          payment_term_code: this.top.selected ? this.top.selected.name : null,
          reason_id: this.reason.selected.ID,
          reason_name: this.reason.selected.reason,
          current_credit_limit: this.customerData.credit_limit_request_old,
          current_payment_term_id: this.customerData.payment_term_id,
          current_payment_term_code: this.customerData.payment_term,
          evaluation_period: Number(this.EvaPeriod),
          so_type: this.soType.selected
            ? this.soType.selected
            : [{ ID: 0, Code: "-" }],
          total_sales: this.totalSales,
          total_average_sales: this.calculateData.total_average_sales_old,
          average_payment: this.calculateData.average_payment,
          num_of_late_payment: this.calculateData.numb_of_late_payment,
          average_late_payment: this.calculateData.average_late_payment,
          active_transaction: this.calculateData.active_transaction,
          ar_overdue: this.calculateData.ar_over_due_old,
          status: 1,
          average_sales: this.calculateData.average_sales_response,
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            this.handleSubmitAttachment();
            this.$vs.loading.close();
            this.closeDetail();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Failed",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        });
    },
    mappingAttachment() {
      this.adjustment.Attachment.map((v) => {
        this.fileAttachment.push({
          ListID: v.ListID,
          NameFile: v.NameFile,
          PathFile: v.PathFile,
        });
      });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.file.length; i++) {
          if (
            this.file[i].type != "application/pdf" &&
            this.file[i].type != "image/jpeg" &&
            this.file[i].type != "image/jpg" &&
            this.file[i].type != "image/png" &&
            this.file[i].type != "application/msword" &&
            this.file[i].type !=
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
            this.file[i].type != "application/vnd.ms-excel" &&
            this.file[i].type !=
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            this.$vs.notify({
              title: "Error",
              text: "File type not allowed",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return false;
          }
        }

        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
          this.newAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        this.handleSubmitAttachment();
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      objectURL = file.PathFile;

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(data, index) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Warning",
        text: "Are you sure to delete this data?",
        accept: () => {
          this.$vs.loading();

          this.$http
            .delete("/api/v1/master/productFestives/attachment/" + data.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  title: "Success",
                  text: "Data deleted successfully",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
                this.fileAttachment = this.fileAttachment.filter((r, i) => {
                  return i != index;
                });
                this.IDAttachment = this.IDAttachment.filter((r, i) => {
                  return r != data.id;
                });
              }
              this.$vs.loading.close();
            });
        },
      });
    },
    handleSubmitAttachment() {
      console.log(this.newAttachment);

      if (this.newAttachment.length > 0) {
        const formData = new FormData();
        for (let index = 0; index < this.newAttachment.length; index++) {
          formData.append("attachments[]", this.newAttachment[index].File);
        }
        formData.append("id", this.id);

        this.$http
          .post(
            "/api/v1/master/create-credit-limit-request-attachment",
            formData
          )
          .then((resp) => {
            if (resp.status == "success") {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Failed",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          });
      }
    },
    onImageChange(e, type) {
      //save image to customerData.customer_image
      const file = e.target.files[0];
      if (type == "ktp") {
        this.datasImg.img_ktp = file;
        this.datasImg.urlKtp = URL.createObjectURL(file);
      } else if (type == "invoice") {
        this.datasImg.img_invoice = file;
        this.datasImg.urlInvoice = URL.createObjectURL(file);
      } else if (type == "npwp") {
        this.datasImg.img_npwp = file;
        this.datasImg.urlNpwp = URL.createObjectURL(file);
      } else if (type == "sppkp") {
        this.datasImg.img_sppkp = file;
        this.datasImg.urlSppkp = URL.createObjectURL(file);
      } else if (type == "outlet") {
        this.datasImg.img_outlet = file;
        this.datasImg.urlOutlet = URL.createObjectURL(file);
      } else if (type == "cl") {
        this.datasImg.img_cl = file;
        this.datasImg.urlCl = URL.createObjectURL(file);
      } else if (type == "additional") {
        this.datasImg.img_additional = e.target.files;
      }

      console.log("fileImage", this.fileImage);
    },
    handleUploadImage(description) {
      let file;
      if (description == "ktp") {
        //check if ktp image not found
        if (this.datasImg.img_ktp == null) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "KTP Image is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        file = this.datasImg.img_ktp;
      } else if (description == "outlet") {
        if (this.datasImg.img_outlet == null) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Outlet Image is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        file = this.datasImg.img_outlet;
      } else if (description == "npwp") {
        if (this.datasImg.img_npwp == null) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "NPWP Image is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        file = this.datasImg.img_npwp;
      } else if (description == "sppkp") {
        if (this.datasImg.img_sppkp == null) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "SPPKP Image is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        file = this.datasImg.img_sppkp;
      } else if (description == "invoice") {
        if (this.datasImg.img_invoice == null) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Invoice Image is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        file = this.datasImg.img_invoice;
      } else if (description == "cl_image") {
        if (this.datasImg.img_cl == null) {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "CL Image is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        file = this.datasImg.img_cl;
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Failed",
          text: "Image not found",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      const formData = new FormData();
      formData.append("attachments[]", file);
      formData.append("description", description);
      formData.append("status", 1);
      formData.append("customer_id", this.customer.selected.id);
      this.$vs.loading();
      this.$http
        .post("/api/v1/master/customer-update-image", formData)
        .then((resp) => {
          if (resp.status == "success") {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            // this.getAllCustomerByID();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Failed",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$refs.file.files[0] = null;
          this.$vs.loading.close();
        });
    },
  },
  watch: {
    "create.territory": function (val) {
      if (val) {
        this.errors.clear();
        this.create.territory = val.id ? val.id : val.ID;
      } else {
        this.create.territory = null;
      }
    },
    // "customer.selected": function (val) {
    //   if (val) {
    //     this.IDAttachment = [];
    //     this.isCalculate = false;
    //     this.calculateData = {
    //       total_sales: 0,
    //       average_sales: "-",
    //       average_payment: 0,
    //       numb_of_late_payment: 0,
    //       average_late_payment: 0,
    //       active_transaction: 0,
    //       ar_over_due: 0,
    //       average_sales_response: 0,
    //     };
    //     this.changeCustomer += 1;
    //     if (this.changeCustomer > 1) {
    //       this.create.creditLimitRequest = 0;
    //       this.create.paymentTermRequest = "";
    //       this.create.dateApply = null;
    //       this.create.notes = "";
    //       this.create.request = "";
    //       this.top.selected = { id: 0, name: "" };

    //       this.reason.selected = { reason: "", id: 0 };
    //       this.fileAttachment = [];
    //       this.datasImg = {
    //         img_ktp: null,
    //         img_outlet: null,
    //         img_npwp: null,
    //         img_sppkp: null,
    //         img_invoice: null,
    //         img_cl: null,
    //         urlKtp: "",
    //         urlOutlet: "",
    //         urlNpwp: "",
    //         urlSppkp: "",
    //         urlInvoice: "",
    //         urlCl: "",
    //       };
    //       this.IDAttachment = [];
    //     }

    //     if (val.id) {
    //       this.getAllCustomerByID();
    //     } else {
    //       this.customerData = {};
    //     }
    //   }
    // },
    "create.creditLimitRequest": function (val) {
      if (this.customerData.credit_limit_request_old !== 0) {
        // Hitung perubahan persentase
        this.percentageChange =
          ((val - this.customerData.credit_limit_request_old) /
            this.customerData.credit_limit_request_old) *
          100;

        this.create.paymentTermRequest = this.percentageChange.toFixed(2) + "%";
        if (val >= this.customerData.credit_limit_request_old) {
          this.isCL = true;
        } else {
          this.isCL = false;
        }
      }
    },
    "create.dateApply": function (val) {
      if (val) {
        this.errors.clear();
        this.create.dateApply = val;
        console.log("ini lognya", this.create.dateApply);
      } else {
        this.create.dateApply = null;
      }
    },
    "soType.selected": function () {
      if (this.soType.selected.length > 1) {
        for (let index = 0; index < this.soType.selected.length; index++) {
          if (this.soType.selected[index].ID == 0) {
            this.soType.selected = [{ ID: 0, Code: "-" }];
          }
        }
      }
    },
    "customerData.evaluation_period": function () {
      if (this.changeData == 1) {
        if (this.customerData.evaluation_period < 3) {
          this.$vs.notify({
            color: "warning",
            title: "Warning",
            text: "Evaluation Period must be greater than 3",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check-circle",
          });
        }
      }
    },
    customerData: function () {
      if (this.customerData.credit_limit == 0) {
        this.create.creditLimitRequest = 0;
      }
    },
    "top.selected": function (val) {
      if (val) {
        //check if top selected is bigger than current top
        // console.log("new top", val.due_invoice_period);
        // console.log("current top", this.customerData.payment_term_due_periode);
        if (
          val.due_invoice_period > this.customerData.payment_term_due_periode
        ) {
          this.isTop = false;
        } else {
          this.isTop = true;
        }
      }
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        //format date 2024-03-25T21:01:23Z to 25 Mar 2024 21:01
        return moment.utc(value).format("DD MMM YYYY HH:mm");
      }
    },
  },
};
</script>
<style>
.container {
  display: flex;
  justify-content: space-between;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
}
</style>
